/* eslint-disable no-return-assign */
import React from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";
import renderHTML from "../helpers/renderHTML";

import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";

class PageAfterWorkTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PopupOpened: false,
      allImages: [],
      postHeading: "",
      postText: "We’ve worked together to prepare delicious dishes!",
      nav1: null,
      nav2: null
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  togglePopup() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ PopupOpened: !this.state.PopupOpened });
  }

  updateContent(e) {
    const photoData = e.target.closest(".photo").nextElementSibling;
    const newPhotos = [];

    for (let i = 0; i < photoData.childElementCount; i += 1) {
      newPhotos.push(photoData.childNodes[i].firstChild.firstChild.dataset.src);
    }
    this.setState({ allImages: newPhotos });

    const textData = e.target.closest(".img-wrap").nextElementSibling.firstChild.textContent;
    this.setState({ postHeading: textData });

    const postData = e.target.closest(".img-wrap").nextElementSibling.lastChild.textContent;
    this.setState({ postText: postData });
  }

  render() {
    const currentPage = this.props.data.wordpressPage;
    const afterwork = this.props.data.allWordpressWpAfterwork;

    const mainSliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      className: "slider__main"
    };

    return (
      <Layout>
        <SEO
          title={currentPage.yoast.title ? currentPage.yoast.title : currentPage.title}
          keywords={currentPage.yoast.metakeywords}
          description={currentPage.yoast.metadesc}
        />
        <section className="afterwork">
          <div className="section__header portfolio-list__header">
            <h2
              className="section__title--large"
              dangerouslySetInnerHTML={renderHTML(currentPage.title)}
            />
          </div>
          <div className="container">
            <div className="posts">
              {afterwork.nodes.map(option => {
                return option.title != null ? (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                  <div
                    className="post"
                    onClick={e => {
                      this.updateContent(e);
                      this.togglePopup();
                    }}
                  >
                    <figure className="photo">
                      <div className="img-wrap">
                        {option.acf.gallery[0].item !== null ? (
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                            alt={option.title}
                            data-src={
                              option.acf.gallery[0].item.localFile.childImageSharp.original.src
                            }
                            className="lazyload"
                          />
                        ) : null}
                      </div>
                      <figcaption>
                        <h3 className="post__heading post-top__heading">{option.title}</h3>
                        <div
                          className="post__text post-top__text"
                          dangerouslySetInnerHTML={renderHTML(option.content)}
                        />
                      </figcaption>
                    </figure>
                    <ul>
                      {option.acf.gallery.map(item => {
                        if (item.item !== null) {
                          return (
                            <li>
                              <figure ref={this.myRef}>
                                {item.item !== null ? (
                                  <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                                    data-src={item.item.localFile.childImageSharp.original.src}
                                    alt={item.item.title}
                                    data-expand="-20"
                                    className="lazyload"
                                  />
                                ) : null}
                              </figure>
                            </li>
                          );
                        }
                        return "";
                      })}
                    </ul>
                  </div>
                ) : null;
              })}
            </div>
          </div>

          <div
            id="zoombox"
            className={`photo-popup zoombox ${this.state.PopupOpened ? "show" : "hide"}`}
          >
            <div className="zoombox-content">
              <div className="zoombox-main">
                <Slider
                  asNavFor={this.state.nav2}
                  ref={slider => (this.slider1 = slider)}
                  {...mainSliderSettings}
                >
                  {this.state.allImages.map(image => {
                    return (
                      <div>
                        <img src={image} alt={image} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="zoombox-miniatures">
                <Slider
                  asNavFor={this.state.nav1}
                  ref={slider => (this.slider2 = slider)}
                  slidesToShow={this.state.allImages.length > 5 ? 5 : this.state.allImages.length}
                  swipeToSlide
                  focusOnSelect
                  className="slider__miniatures"
                >
                  {this.state.allImages.map(image => {
                    return (
                      <div>
                        <img src={image} alt={image} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="zoombox-description">
                <h3 className="post__heading">{this.state.postHeading}</h3>
                <div className="post__text">
                  <p>{this.state.postText}</p>
                </div>
              </div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a to="/" className="zoombox-close" onClick={this.togglePopup}>
              <span>Close</span>
            </a>
          </div>
          <div className="zoombox-overlay" />
        </section>
      </Layout>
    );
  }
}

export default PageAfterWorkTemplate;

export const pageQuery = graphql`
  query currentAfterworkPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      id
      excerpt
      yoast {
        title
        metakeywords
        metadesc
      }
    }

    allWordpressWpAfterwork {
      nodes {
        title
        content
        path
        acf {
          gallery {
            item {
              source_url
              title
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
